import axios from 'axios'
import { createRouter, createWebHistory } from 'vue-router'

/* eslint-disable */
const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    children: [
      // 系統總覽區塊
      {
        path: '',
        name: 'overview',
        component: () => import('../views/Home.vue')
      },
      {
        path: 'events',
        name: 'events',
        component: () => import('../views/EventLists.vue')
      },
      // 使用者管理區塊
      {
        path: 'users',
        name: 'users',
        component: () => import('../views/users/Report.vue'),
        children: [
          {
            path: '',
            name: 'member-fee',
            component: () => import('../views/users/MemberFeeReport.vue')
          },
          {
            path: 'deposit',
            name: 'deposit-report',
            component: () => import('../views/users/UserDepositReport.vue')
          }
        ],
      },
      // 儲值
      {
        path: 'deposit',
        name: 'deposit',
        component: () => import('../views/deposit/Deposit.vue'),
        children: [
          {
            path: '',
            name: 'deposit-list',
            component: () => import('../views/deposit/DepositList.vue'),
          },
          {
            // REMOVE deposit-detail
            path: 'detail',
            name: 'deposit-detail',
            component: () => import('../views/deposit/DepositDetail.vue'),
          },
        ],
      },
      // 系統報表區塊
      {
        path: 'reports',
        name: 'reports',
        component: () => import('../views/ReportManagement.vue')
      },
      // 營運分析
      /*
      {
        path: 'analyze',
        name: 'analyze',
        component: () => import('../views/ServiceAnalyze.vue')
      },
      */
      // 系統設定
      {
        path: 'system',
        name: 'system',
        component: () => import('../views/ServiceNotAvaliable.vue')
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('../views/settings/Settings.vue'),
        children: [
          {
            path: 'reset-password',
            name: 'reset-password',
            component: () => import('../views/settings/ResetPassword.vue')
          },
        ]
      },
      // 費用設定區塊
      {
        path: 'fee',
        name: 'fee',
        component: () => import('../views/fee/Fee.vue'),
        children: [
          {
            path: 'configs',
            name: 'fee-configs',
            component: () => import('../views/fee/FeeConfigs.vue')
          }
        ]
      },
      // Miscs
      {
        path: 'chargers',
        name: 'chargers',
        component: () => import('../views/ChargerDetail.vue')
      },
      {
        path: '/404',
        name: '404',
        component: () => import('../views/404.vue')
      },
    ]
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404',
  }
]

const router = createRouter({
  base: process.env.BASE_URL,
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0
    }
  }
})

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.interceptors.response.use(
  (response) => { return response},  // if status code is 2xx
  (error) => {
    const status_code = error.response.status

    if (status_code == 401 || status_code == 419) {  // if password incorrect or session expire
      router.push({ name: 'login' })
    }

    return Promise.reject(error)
  }
)

export default router
